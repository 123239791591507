import axios from 'axios'
import S3Api from '@/api/s3'
export const resizeImage = async (
  url,
  _options
) => {
  const options = {
    contentType: "image/jpeg",
    quality: 0.8,
    ..._options
  }
  const calculateSize = (img) => {
    let w = img.width,
      h = img.height;
    if (w > h) {
      if (w > options.maxWidth) {
        h = Math.round((h * options.maxWidth) / w);
        w = options.maxWidth;
      }
    } else {
      if (h > options.maxHeight) {
        w = Math.round((w * options.maxHeight) / h);
        h = options.maxHeight;
      }
    }
    return [w, h];
  };

  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onerror = function (x) {
      URL.revokeObjectURL(this.src);
    };
    img.onload = function () {
      URL.revokeObjectURL(this.src);
      const [newWidth, newHeight] = calculateSize(
        img,
        options.maxWidth,
        options.maxHeight
      );
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      const resultUrl = canvas.toDataURL(options.contentType, options.quality),
        result = {
          url: resultUrl,
          contentType: resultUrl.match(/^data\:([^\;]+)\;base64,/im)[1] || "",
          b64: resultUrl.replace(/^data\:([^\;]+)\;base64,/gim, "")
        };

      canvas.toBlob(
        (blob) => {
          result.size = blob.size;
          resolve(result);
        },
        options.contentType,
        options.quality
      );
    };
  });
};

export const fetchBlob = (url) => {
  return S3Api.getS3PresignedGet(url)
    .then(async (res) => await fetch(res.data, { responseType: 'blob'}))
    .then(async res => await res.blob())
    .then(blob => { return readFileAsDataURL(blob) })
}

export const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    let fileredr = new FileReader();
    fileredr.onload = () => resolve(fileredr.result);
    fileredr.onerror = () => reject(fileredr);
    fileredr.readAsDataURL(file);
  });
}

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const svgToImgDownload = async (svgText, name, width, height ) => {
  // Serialize the svg to string
  const svgString = new XMLSerializer().serializeToString(svgText)
  // Remove any characters outside the Latin1 range
  const decoded = unescape(encodeURIComponent(svgString));
  // Now we can use btoa to convert the svg to base64
  const base64 = btoa(decoded);
  const uriData = `data:image/svg+xml;base64,${base64}`
  const img = new Image()
  img.onerror = (e) => {
    console.log("Not ok");
  }
  img.onload = (x) => {
    const canvas = document.createElement("canvas");
    [canvas.width, canvas.height] = [width, height]
    const ctx = canvas.getContext("2d")
    ctx.drawImage(img, 0, 0, width, height)
    const a = document.createElement("a")
    const quality = 1.0
    a.href = canvas.toDataURL("image/jpeg", quality)
    a.download = name
    a.append(canvas)
    a.click()
    a.remove()
  }
  img.src = uriData
}
