export default [
  "Animals",
  "Architecture",
  "Botanical",
  "Cities and Towns",
  "Contemporary",
  "Decorative",
  "Famous People",
  "Humour",
  "Landscape and Scenic",
  "Motorsport",
  "Mythical",
  "Seascape and Coastal",
  "Still Life",
  "Transport",
]
