import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/s3`

export default {
  deleteObject(filename) {
    return axios.post(api + '/deleteObject', { filename })
  },
  getS3PresignedPost (filename) {
    return axios.post(api + '/getS3PresignedPost', { filename })
  },
  getS3PresignedGet(filename) {
    return axios.post(api + '/getS3PresignedGet', { filename })
  }
}
