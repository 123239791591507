export default [
  "Aberdeenshire and Moray",
  "Argyll and Bute",
  "Ayrshire and Arran",
  "Cumbria and Northumberland",
  "Dumfries and Galloway",
  "Dundee and Angus",
  "Edinburgh and the Lothians",
  "England",
  "Fife",
  "Greater Glasgow and the Clyde Valley",
  "Highlands",
  "Ireland",
  "Orkney and Shetland",
  "Overseas",
  "Perth and Kinross",
  "Scottish Borders",
  "Skye, Rum and Eigg",
  "Stirling, The Trossachs, Loch Lomond and the Forth Valley",
  "Western Isles",
]
